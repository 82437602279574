<template>
    <div>

        <v-sheet v-if="isLoading || !faceMeshEditor" class="text-center mt-10">
            <div class="font-weight-medium text-body-1 pb-4">Loading...</div>
            <v-progress-circular size="48" :width="2" color="primary" indeterminate></v-progress-circular>
        </v-sheet>

        <v-sheet v-else class="pb-4 mx-auto">
            <div class="position-relative">


                <ThreeJSCanvasImage ref="canvasImage" :mesh="faceMeshEditor.planeMesh"
                    :imageWidth="faceMeshEditor.imageWidth" :imageHeight="faceMeshEditor.imageHeight"
                    :style="{ visibility: showOriginal ? 'hidden' : 'visible' }" />

                <v-btn text color="rgb(84, 84, 84)"
                    style="border: 2px solid #e9e9e9; font-size: 20px; position: absolute; top: 10px; left: 10px; z-index: 1; background-color: white;"
                    class="rounded-md font-weight-medium" @touchstart="showOriginal = true"
                    @mousedown="showOriginal = true" @mouseup="showOriginal = false" @mouseleave="showOriginal = false"
                    @touchend="showOriginal = false" :class="{ 'is-active': showOriginal }">
                    {{ $t('button.view-original') }}
                </v-btn>
                <v-img v-if="showOriginal" :src="item.originalImageUrl" :key="item.originalImageUrl" contain
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                </v-img>

                <div v-if="isSaving" class="saving-overlay d-flex justify-center align-center">
                    <v-progress-circular size="48" :width="2" color="primary" indeterminate></v-progress-circular>
                </div>
                <div class="edit-toolbar">

                    <div class="edit-toolbar-icons" :class="{ 'hidden': editMode !== null }">
                        <v-btn icon class="mx-2" @click="editMode = 'master'">
                            <v-img contain v-bind="getIconProps('master')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editMode = 'faceWidth'">
                            <v-img contain v-bind="getIconProps('faceWidth')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editMode = 'cheekSize'">
                            <v-img contain v-bind="getIconProps('cheek')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editMode = 'midFace'">
                            <v-img contain v-bind="getIconProps('midFace')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editMode = 'eyeSize'">
                            <v-img contain v-bind="getIconProps('eye')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editMode = 'noseSize'">
                            <v-img contain v-bind="getIconProps('nose')" />
                        </v-btn>

                        <v-btn icon class="mx-2" @click="editMode = 'brightness'">
                            <v-img contain v-bind="getIconProps('brightness')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="toggleFlip">
                            <v-img contain v-bind="getIconProps('flip')" />
                        </v-btn>
                    </div>

                    <div class="edit-slider" :class="{ 'hidden': editMode === null }">
                        <v-btn icon class="mx-2" @click="editValues.brightness = 0.0" v-if="editMode === 'brightness'">
                            <v-img contain v-bind="getIconProps('brightness')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editValues.flip = false" v-if="editMode === 'flip'">
                            <v-img contain v-bind="getIconProps('flip')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="masterValue = 0.0" v-if="editMode === 'master'">
                            <v-img contain v-bind="getIconProps('master')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editValues.leftEye = editValues.rightEye = 0.0"
                            v-if="editMode === 'eyeSize'">
                            <v-img contain v-bind="getIconProps('eye')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editValues.leftNostril = editValues.rightNostril = 0.0"
                            v-if="editMode === 'noseSize'">
                            <v-img contain v-bind="getIconProps('nose')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editValues.leftCheek = editValues.rightCheek = 0.0"
                            v-if="editMode === 'cheekSize'">
                            <v-img contain v-bind="getIconProps('cheek')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editValues.faceWidth = 0.0" v-if="editMode === 'faceWidth'">
                            <v-img contain v-bind="getIconProps('faceWidth')" />
                        </v-btn>
                        <v-btn icon class="mx-2" @click="editValues.midFace = 0.0" v-if="editMode === 'midFace'">
                            <v-img contain v-bind="getIconProps('midFace')" />
                        </v-btn>

                        <!-- 단일 슬라이더 -->
                        <template v-if="['brightness', 'faceWidth', 'midFace'].includes(editMode)">
                            <v-slider v-model="editValues[editMode]" :min="-50" :max="50" :step="1" :thumb-size="24"
                                hide-details class="mx-1" color="#ef7fab" />
                        </template>
                        <template v-else-if="editMode === 'master'">
                            <v-slider v-model="masterValue" :min="-100" :max="100" :step="1" :thumb-size="24"
                                hide-details class="mx-1" color="#ef7fab" />
                        </template>
                        <template v-else-if="editMode === 'midFace'">
                            <v-slider v-model="editValues[editMode]" :min="-50" :max="50" :step="1" :thumb-size="24"
                                hide-details class="mx-1" color="#ef7fab" />
                        </template>
                        <!-- 두개 슬라이더 -->
                        <template v-else-if="editMode === 'eyeSize'">
                            <template v-for="eye in ['leftEye', 'rightEye']">
                                <v-slider v-model="editValues[eye]" :min="-50" :max="50" :step="1" :thumb-size="24"
                                    hide-details class="mx-1" color="#ef7fab" />
                            </template>
                        </template>
                        <template v-else-if="editMode === 'noseSize'">
                            <template v-for="nostril in ['leftNostril', 'rightNostril']">
                                <v-slider v-model="editValues[nostril]" :min="-50" :max="50" :step="1" :thumb-size="24"
                                    hide-details class="mx-1" color="#ef7fab" />
                            </template>
                        </template>
                        <template v-else-if="editMode === 'cheekSize'">
                            <template v-for="cheek in ['leftCheek', 'rightCheek']">
                                <v-slider v-model="editValues[cheek]" :min="-80" :max="80" :step="1" :thumb-size="24"
                                    hide-details class="mx-1" color="#ef7fab" />
                            </template>
                        </template>
                        <!-- 두개 슬라이더 -->
                        <v-btn icon class="mx-1" @click="editMode = null">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>



            <div class="d-flex justify-center mb-6 mt-4" style="column-gap: 10px;">
                <v-btn width="34%" large text color="rgb(84, 84, 84)" style="border: 2px solid #e9e9e9; font-size: 22px"
                    class="rounded-md font-weight-medium" :to="`/mypage/faceberryDetail/${item.id}`">
                    {{ $t("button.cancel-edit") }}
                </v-btn>

                <v-btn width="34%" large text style="border: 2px solid #e9e9e9; font-size: 22px;" depressed
                    class="sela rounded-md font-weight-medium" @click="completeEdit" :disabled="isSaving">
                    {{ $t("button.complete-edit") }}
                </v-btn>
            </div>
        </v-sheet>

    </div>
</template>

<script>

import { fetchMyPictureItem, updateMyPictureItem } from "@/models/MyPicture"
import ThreeJSCanvasImage from "@/views/components/ThreeJSCanvasImage.vue"
import FaceMesh from "@/models/face-edit/face-mesh"
import FaceMeshEditor from "@/models/face-edit/face-mesh-editor"


export default {

    components: {
        ThreeJSCanvasImage
    },

    data: () => ({
        showOriginal: false,
        item: null,
        isLoading: true,
        isSaving: false,
        editMode: null,
        masterValue: 0.0,
        editValues: {
            brightness: 0,

            flip: false,

            leftEye: 0.0,
            rightEye: 0.0,

            leftNostril: 0.0,
            rightNostril: 0.0,

            leftCheek: 0.0,
            rightCheek: 0.0,

            faceWidth: 0.0,

            midFace: 0.0,
        },
        faceMeshEditor: null,
        applyTimer: null,
        iconConfigs: {
            brightness: {
                src: require('@/assets/menus/faceberry_brightness.png'),
                width: 32,
                height: 32
            },
            flip: {
                src: require('@/assets/menus/faceberry_flipx.png'),
                width: 32,
                height: 32
            },
            master: {
                src: require('@/assets/menus/faceberry_master.png'),
                width: 32,
                height: 32
            },
            eye: {
                src: require('@/assets/menus/faceberry_eye.png'),
                width: 32,
                height: 32
            },
            nose: {
                src: require('@/assets/menus/faceberry_nose.png'),
                width: 32,
                height: 32
            },
            cheek: {
                src: require('@/assets/menus/faceberry_cheek.png'),
                width: 32,
                height: 32
            },
            faceWidth: {
                src: require('@/assets/menus/faceberry_facewidth.png'),
                width: 32,
                height: 32
            },

            midFace: {
                src: require('@/assets/menus/faceberry_midup.png'),
                width: 32,
                height: 32
            }
        }
    }),

    async created() {
    },

    mounted() {
        fetchMyPictureItem(this, this.$route.params.id)
            .then((item) => {
                this.item = item
                const faceMesh = new FaceMesh(item.imageUrl)
                return faceMesh.load()
                    .then((faceMesh) => {
                        this.faceMeshEditor = new FaceMeshEditor(faceMesh, () => {
                            this.$refs.canvasImage.updateRender();
                        })
                    })
            })
            .catch((e) => {
                console.log(e)
                alert(this.$t("alert.error_failed_get_information") + " " + e)
                this.$router.back()
            })
            .finally(() => {
                this.isLoading = false
            })
    },

    beforeDestroy() {
        if (this.faceMeshEditor) {
            this.faceMeshEditor.faceMesh.unload()
        }
    },

    methods: {
        async completeEdit() {
            if (this.isSaving) return
            this.isSaving = true
            const blob = await this.$refs.canvasImage.getScreenShotBlob()
            try {
                const imageFile = new File([blob], "edited.jpg", { type: "image/jpeg" })
                await updateMyPictureItem(this, this.item.id, imageFile, null, {
                    ...this.editValues,
                    masterValue: this.masterValue,
                    agent: window.navigator.userAgent,
                    rendererSettings: this.$refs.canvasImage.getRendererSettings(),
                    textureColorSpace: this.faceMeshEditor.faceMesh.originalColorSpace,
                })
                this.$router.replace(`/mypage/faceberryDetail/${this.item.id}`)
            } catch (e) {
                console.error(e)
                alert(this.$t("alert.error_failed_save_information"))
            } finally {
                this.isSaving = false
            }
        },

        toggleFlip() {
            this.editValues.flip = !this.editValues.flip
        },

        getIconProps(iconName) {
            const config = this.iconConfigs[iconName];
            return {
                src: config.src,
                width: config.width,
                height: config.height
            };
        }
    },

    watch: {
        editValues: {
            deep: true,
            handler(newVal, oldVal) {
                const faceMeshEditor = this.faceMeshEditor
                if (faceMeshEditor) {
                    faceMeshEditor.applyEditValues(newVal);
                }
            }
        },
        masterValue: {
            handler(newVal, oldVal) {
                this.editValues = {
                    ...this.editValues,
                    leftEye: newVal * 0.3,
                    rightEye: newVal * 0.3,

                    leftNostril: newVal * -0.3,
                    rightNostril: newVal * -0.3,

                    leftCheek: newVal * -0.3,
                    rightCheek: newVal * -0.3,

                    faceWidth: newVal * -0.6,

                    midFace: newVal * -0.3,
                }
            }
        }
    }

}
</script>

<style scoped>
.position-relative {
    position: relative;
}

.edit-toolbar {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px;
    border-radius: 8px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-toolbar-icons {
    display: flex;
    align-items: center;
    overflow-x: auto;
    column-gap: 16px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

.edit-toolbar-icons::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

.edit-toolbar-icons.hidden {
    display: none;
}

.edit-toolbar .v-btn {
    color: white;
    width: 48px;
    height: 48px;
}

.saving-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
}


.edit-slider {
    display: flex;
    align-items: center;
    column-gap: 8px;
    white-space: nowrap;
    width: 100%;
}

.edit-slider.hidden {
    display: none;
}
</style>