function _rawToMyPicture(vue, item) {
  let processStatus = "ready"
  let completeStatus = "완료예정"

  if (item.status == 2) {
    processStatus = "complete"
    completeStatus = "완료"
  } else if (item.automate_status == 2) {
    processStatus = "effect"
    completeStatus = "후보정 대기중"
  }

  return {
    ...item,
    thumbnailUrl:
      processStatus != "ready" ? vue.$imageUrl(item.complete_url.replace(".jpg", "_thumbnail.jpg"), item.updated) : vue.$imageUrl("/upload/auto_photo/resized/" + item.filename, item.updated),
    originalImageUrl: vue.$imageUrl("/upload/auto_photo/selected/" + item.filename, item.updated),
    imageUrl: processStatus != "ready" ? vue.$imageUrl(item.complete_url, item.updated) : vue.$imageUrl("/upload/auto_photo/selected/" + item.filename, item.updated),
    completeDate: vue.$getTDaysLater(item.complete_date || item.created, processStatus == "ready" ? 3 : 1, true),
    completeStatus,
    editable: processStatus == "complete",
    processStatus: processStatus,
  }
}

export async function fetchMyPictureItemList(vue, user_id) {
  return vue.$http
    .post("/api/request/select/byUser", {
      params: {
        user_id,
      },
    })
    .then((res) => {
      return { data: res.data.map((x) => _rawToMyPicture(vue, x)), headers: res.headers }
    })
}

export async function fetchMyPictureItem(vue, id) {
  return vue.$http
    .post("/api/request/select/byId", {
      id,
    })
    .then((res) => {
      return _rawToMyPicture(vue, res.data[0])
    })
}

export async function updateMyPictureItem(vue, id, imageFile, userComposite, log) {
  const formData = new FormData()
  formData.append("image_file", imageFile)
  if (userComposite) {
    formData.append("user_composite", JSON.stringify(userComposite))
  }
  if (log) {
    formData.append("log", JSON.stringify(log))
  }
  await vue.$http.put(`/api/request/rework/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export async function uploadFaceberryPrintImage(vue, id, imageFile) {
  const formData = new FormData()
  formData.append("image_file", imageFile)
  const result = await vue.$http.post(`/api/request/upload/faceberry/print/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  return result.data
}
