<template>
    <div>
        <v-sheet class="pb-4 mx-auto">

            <div class="d-flex justify-center align-center full-width mb-2">
                <img src="@/assets/menus/print.png" style="width: 36px" class="mr-2" />
                <p class="text-body-1 mt-1 ml-5px mb-2 grey--text text--darken-1 font-weight-medium">
                    {{ $t("page.mypage.picture.operation-guide-adjustment-size") }}
                </p>
            </div>

            <div class="d-flex justify-center align-center full-width canvas-container" ref="canvasContainer">
                <div ref="topy" class="guide-line"></div>
                <div ref="topy_text" class="guide-line-text">정수리</div>
                <canvas ref="canvas" class="canvas-image"></canvas>
                <div ref="boty_text" class="guide-line-text">턱끝</div>
                <div ref="boty" class="guide-line"></div>

                <div ref="centerx" class="guide-line-horz"></div>

                <v-img src="@/assets/menus/sam_3x4.jpg" v-if="showSampleView && printType.id == 1"
                    class="sample-view-image" />
                <v-img src="@/assets/menus/sam_35x45.jpg" v-if="showSampleView && printType.id == 0"
                    class="sample-view-image" />
                <v-img src="@/assets/menus/sam_pass.jpg" v-if="showSampleView && printType.id == 2"
                    class="sample-view-image" />
            </div>

            <div class="px-16 my-4">
                <div class="d-flex align-center my-2">
                    <span>크기</span>
                    <v-slider v-model="manualScale" :min="0.5" :max="1.5" :step="0.01" :thumb-size="24" hide-details
                        class="mx-1" color="#ef7fab" />
                </div>

                <div class="d-flex align-center my-2">
                    <span>좌우</span>
                    <v-slider v-model="manualDx" :min="-400" :max="400" :step="1" :thumb-size="24" hide-details
                        class="mx-1" color="#ef7fab" />
                </div>

                <div class="d-flex align-center my-2">
                    <span>상하</span>
                    <v-slider v-model="manualDy" :min="-400" :max="400" :step="1" :thumb-size="24" hide-details
                        class="mx-1" color="#ef7fab" />
                </div>
            </div>

            <div class="mt-4 d-flex align-center px-8">
                <v-btn text color="rgb(84, 84, 84)" style="border: 2px solid #e9e9e9; font-size: 1rem"
                    class="rounded-md font-weight-medium" @mousedown="showSampleView = true"
                    @touchstart="showSampleView = true" @mouseup="showSampleView = false"
                    @mouseleave="showSampleView = false" @touchend="showSampleView = false">
                    {{ $t("page.mypage.picture.sample-view-btn-title") }}
                </v-btn>
                <p class="grey--text text--darken-1 font-weight-medium ml-2 mb-0" style="font-size: 0.7rem;">
                    {{ $t("page.mypage.picture.operation-guide-warning") }}
                </p>
            </div>

            <div style="height: 1px; background-color: #e9e9e9; margin-top: 10px; margin-bottom: 10px; width: 100%">
            </div>

            <div class="d-flex justify-center mb-6 mt-4" style="column-gap: 10px;">
                <v-btn width="29%" large text color="rgb(84, 84, 84)" style="border: 2px solid #e9e9e9; font-size: 22px"
                    class="rounded-md font-weight-medium" :to="`/mypage/faceberryDetail/${$route.params.id}`">
                    {{ $t("button.go-back") }}
                </v-btn>

                <v-btn width="29%" large text color="rgb(84, 84, 84)" style="border: 2px solid #e9e9e9; font-size: 22px"
                    class="rounded-md font-weight-medium" @click="saveEdit">
                    {{ $t("button.save") }}
                </v-btn>

                <v-btn width="29%" large text style="border: 2px solid #e9e9e9; font-size: 22px"
                    class="sela rounded-md font-weight-medium" @click="completeEdit" :disabled="isSaving">
                    {{ $t("button.print") }}
                </v-btn>
            </div>

        </v-sheet>

        <div v-if="isLoading || isSaving" class="loading-overlay">
            <div class="loading-content">
                <div class="font-weight-medium text-body-1 pb-4">Loading...</div>
                <v-progress-circular size="48" :width="2" color="primary" indeterminate></v-progress-circular>
            </div>
        </div>

    </div>
</template>

<script>

import { fetchMyPictureItem, uploadFaceberryPrintImage } from "@/models/MyPicture"
import FaceAlignment from "@/models/faceberry/face-alignment"
import { PrintTypes, getPrintType } from "@/models/faceberry/print-types"
import { generateGridPrintImageFile } from "@/models/faceberry/grid-print"


export default {

    components: {
    },

    props: {
    },

    data: () => ({
        faceAlignment: null,
        printType: null,
        isLoading: true,
        isSaving: false,
        topY: null,
        botY: null,
        item: null,
        showSampleView: false,

        manualScale: 1.0,
        manualDx: 0,
        manualDy: 0,
    }),

    async created() {
    },

    mounted() {
        this.printType = getPrintType(this.$route.query.printType)
        this.topY = this.printType.alignment.topY
        this.botY = this.printType.alignment.botY
        fetchMyPictureItem(this, this.$route.params.id)
            .then((item) => {
                this.item = item
                const faceAlignment = new FaceAlignment(item.imageUrl)
                return faceAlignment.load()
            })
            .then((faceAlignment) => {
                this.faceAlignment = faceAlignment
                this.render();
            })
            .catch((e) => {
                console.log(e)
                alert(this.$t("alert.error_failed_get_information"))
                this.$router.back()
            })
            .finally(() => {
                this.isLoading = false
            })

        window.addEventListener("resize", this.render)
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.render)
    },

    methods: {
        async completeEdit() {
            try {
                const file = await generateGridPrintImageFile(this.$refs.canvas, this.printType)
                const result = await uploadFaceberryPrintImage(this, this.$route.params.id, file)
                // console.log(result)
                // window.open(result.url, "_blank")

                this.$router.push({
                    name: "printPicPay",
                    params: {
                        id: this.$route.params.id,
                        pic: { ...this.item, printUrl: result.url }
                    }
                })
            }
            catch (e) {
                console.log(e)
                alert(this.$t("alert.error_failed_save_image"))
            }
            finally {
                this.isSaving = false
            }
        },

        render() {
            this.faceAlignment.render(this.$refs.canvas, this.printType.alignment, this.manualScale, this.manualDx, this.manualDy)

            // 캔버스의 실제 표시 크기 가져오기
            const containerRect = this.$refs.canvasContainer.getBoundingClientRect()
            const canvasRect = this.$refs.canvas.getBoundingClientRect()
            const originalHeight = this.$refs.canvas.height;
            const scale = canvasRect.height / originalHeight

            const topOffset = canvasRect.top - containerRect.top

            const topPosition = (this.faceAlignment.guideLine1Y * scale) + topOffset - 5
            this.$refs.topy.style.top = `${topPosition}px`
            this.$refs.topy_text.style.top = `${topPosition + 3}px`

            if (this.faceAlignment.guideLine152Y) {
                const botPosition = (this.faceAlignment.guideLine152Y * scale) + topOffset

                this.$refs.boty.style.visibility = "visible"
                this.$refs.boty.style.top = `${botPosition}px`

                this.$refs.boty_text.style.visibility = "visible"
                this.$refs.boty_text.style.top = `${botPosition - 20}px`
            }
            else {
                this.$refs.boty.style.visibility = "hidden"
                this.$refs.boty_text.style.visibility = "hidden"
            }
        },

        saveEdit() {
            const canvas = this.$refs.canvas
            const dataUrl = canvas.toDataURL("image/jpeg", 1.0)
            const a = document.createElement("a")
            a.href = dataUrl
            a.download = "faceberry.jpg"
            a.click()
        }
    },

    watch: {
        manualScale: {
            handler(newVal, oldVal) {

                let oldS = oldVal
                let newS = newVal

                let { baseScale, baseDx, baseDy } = this.faceAlignment.alignRes

                let oldFinal = baseScale * oldS;
                let oldW = this.faceAlignment.image.width * oldFinal;
                let oldH = this.faceAlignment.image.height * oldFinal;
                let centerX = (baseDx + this.manualDx) + (oldW / 2);
                let centerY = (baseDy + this.manualDy) + (oldH / 2);

                let newFinal = baseScale * newS;
                let newW = this.faceAlignment.image.width * newFinal;
                let newH = this.faceAlignment.image.height * newFinal;

                let newDx = centerX - (newW / 2);
                let newDy = centerY - (newH / 2);


                this.manualDx = newDx - baseDx
                this.manualDy = newDy - baseDy

                this.render()

            }
        },
        manualDx: {
            handler(newVal, oldVal) {
                this.render()
            }
        },
        manualDy: {
            handler(newVal, oldVal) {
                this.render()
            }
        }
    }
}
</script>

<style scoped>
.canvas-container {
    position: relative;
}

.canvas-image {
    width: 60%;
}

.guide-line {
    position: absolute;
    width: 95%;
    height: 1px;
    background-color: black;
    z-index: 2;
    opacity: 0.5;
}

.guide-line-horz {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: black;
    z-index: 2;
    opacity: 0.5;
}

.guide-line-text {
    position: absolute;
    font-size: 12px;
    color: black;
    left: 0;
    margin-left: 2rem;
    z-index: 2;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.sample-view-image {
    width: 60%;
    position: absolute;
}
</style>
