<template>
  <div>
    <v-sheet class="d-flex flex-column align-center px-6"
      :style="{ 'padding-bottom': $vuetify.breakpoint.mobile ? '150px' : '0px' }">
      <img src="@/assets/warning.png" class="icon mt-2 mt-lg-10"
        :width="$vuetify.breakpoint.mobile ? '40px' : '60px'" />
      <div class="primary--text font-weight-bold mt-2 text-center"
        :style="{ fontSize: $vuetify.breakpoint.mobile ? '20px' : '26px' }">{{ $t("page.picturePrintReq.title") }}</div>
      <div class="text-body-1 text-lg-h6 font-weight-light px-4 text-center">{{
        $t(getResourceKey("page.picturePrintReq.subTitle")) }}
      </div>
      <img :src="require(`@/assets/print/${getResourceKey('center-sample')}.png`)" class="my-4"
        :width="$vuetify.breakpoint.mobile ? '200px' : '250px'" />
      <div class="text-body-1 text-lg-h6 font-weight-light text-center px-8"
        v-html="$t(getResourceKey('page.picturePrintReq.caution'))">
      </div>

      <div class="d-flex align-center mt-4">
        {{ $t("page.pictureDetail.print-message") }}
        <v-select style="width: 72px; margin: 0 6px" class="rounded-10 shrink my-0 mx-4" hide-details outlined dense
          append-icon="mdi-chevron-down" :items="select_list.number" v-model="printCount"
          :menu-props="{ maxHeight: 200 }">
          <template v-slot:selection="{ item }">
            <span class="pl-1 pb-2px">{{ item }}</span>
          </template>
          <template v-slot:append>
            <v-icon style="width: 12px">mdi-chevron-down</v-icon>
          </template>
        </v-select>
        {{ $t("page.pictureDetail.print-count-unit") }}
      </div>
      <div class="warning--text font-weight-medium mt-1 mt-md-2"
        v-html="$t(getResourceKey('page.pictureDetail.print-bottom-message'))">
      </div>
    </v-sheet>

    <!-- 예약하기 하단 -->
    <v-sheet
      style="width: 100%; max-width: 580px; position: fixed; bottom: 0px; padding-bottom: 20px; border-top: 1px solid #e0e0e0; z-index: 7">
      <!-- 최종가격 -->
      <p class="text-end font-danjunghae ml-1 px-6 mt-2 mb-0" style="font-size: 40px; color: #5b4a62">
        <span v-if="selected_coupon" class="mr-2 text-body-2 warning--text font-weight-light">{{
          $t("page.pay.coupon-apply-label", { amount: getDiscountPriceStr }) }}</span>

        {{ $toComma(computed_total_price) }}<span class="text-body-1 ml-1 grey--text text--darken-2">{{ $t("common.krw")
          }}</span>
      </p>

      <!-- 쿠폰, 예약하기 -->
      <div class="d-flex px-6 pb-4">
        <v-btn large width="40%" color="#f1b2ba" style="font-size: 20px; color: #693838"
          class="flex-grow-1 rounded-10 pb-1" depressed outlined @click="showCouponSelectDialog()" v-if="!isFaceberry">
          {{ $t("button.coupon") }}<span v-show="available_coupon_cnt">({{ available_coupon_cnt }})</span>
        </v-btn>

        <v-btn large width="40%" color="#f1b2ba" style="font-size: 20px; color: #693838"
          class="flex-grow-1 rounded-10 pb-1" depressed outlined @click="$router.back()" v-if="isFaceberry">
          {{ $t("button.back") }}<span v-show="available_coupon_cnt">({{ available_coupon_cnt }})</span>
        </v-btn>

        <v-btn large width="40%" color="primary" style="font-size: 25px"
          class="flex-grow-1 rounded-10 pb-1 ml-4 darken-1" depressed @click="onSubmitWithApplicableCouponCheck"
          :loading="pay_loading">
          {{ $t("button.print") }}
        </v-btn>
      </div>
    </v-sheet>

    <MyCouponSelectList type="인쇄" :visible="show_coupon_select" @close="show_coupon_select = false"
      @input="(e) => (show_coupon_select = e)" :key="'coupon' + show_coupon_select_componentKey"
      @apply="(item) => applyCoupon(item)" :showNoCoupon="showNoCoupon" />
    <TwoButtonDialogVue :msg="twobutton_dialog_messsage" @input="twoButtonDialogInputEvent" positiveText="button.pay"
      negativeText="button.coupon-select" @positive="positiveDialogEvent" @negative="negativeDialogEvent"
      :visible="show_twobutton_dialog" :key="show_twobutton_dialog_componentKey" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex"
import MyCouponSelectList from "@/views/components/MyCouponSelectList"
import TwoButtonDialogVue from "@/views/components/TwoButtonDialog"
import { kStorageUrl } from "@/models/PSDLayer"
import payletterService from "@/api/payletter-service"
import { fetchMyPictureItem } from "@/models/MyPicture"
export default {
  components: {
    MyCouponSelectList,
    TwoButtonDialogVue,
  },

  props: {
    pic: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    select_list: {
      number: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    printCount: 1,
    show_coupon_select_componentKey: 0,
    show_coupon_select: false,
    selected_coupon: null,
    available_coupon_cnt: 0,
    canFreePrint: false,

    show_twobutton_dialog: false,
    twobutton_dialog_messsage: "",
    show_twobutton_dialog_componentKey: 0,
    showNoCoupon: true,

    //결제 진행중
    pay_loading: false,

    //payletter window
    payletterWindow: null,
    payletterWindowEventListener: null,
  }),

  created() {
    if (!this.pic) {
      alert(this.$t("alert.error_wrong_access"))
      fetchMyPictureItem(this, this.$route.params.id).then((item) => {
        if ((item.kiosk || '').startsWith('faceberry')) {
          this.$router.replace(`/mypage/faceberryDetail/${this.$route.params.id}`)
        } else {
          this.$router.replace(`/mypage/picDetail/${this.$route.params.id}`)
        }
      })
      return
    }
    // default 가격정보를 가져온다.
    this.getPromotionPrice()
  },

  computed: {
    ...mapState(["default_package_price", "lafia_user", "isSupportAbroadPay"]),
    ...mapGetters(["isKRW"]),

    getDiscountPrice() {
      if (!this.selected_coupon) {
        return 0
      }
      const price = this.selected_coupon.discount_KRW || 0
      return Math.min(price, this.default_package_price.printPrice || 3900)
    },

    getDiscountPriceStr() {
      return `${this.$toComma(Math.min(this.getDiscountPrice || 0, this.default_package_price.printPrice || 3900))}${this.$t("common.krw")}`
    },

    computed_total_price() {
      const printPrice = this.default_package_price.printPrice || 3900
      if (this.canFreePrint) {
        // 한장이 무료.
        return Math.max((this.printCount - 1) * printPrice - this.getDiscountPrice, 0)
      } else {
        return Math.max(this.printCount * printPrice - this.getDiscountPrice, 0)
      }
    },

    computed_total_price_original() {
      return (this.default_package_price.printPrice || 3900) * this.printCount
    },

    isFaceberry() {
      return (this.pic.kiosk || '').startsWith('faceberry')
    },
  },

  mounted() {
    this.tossInit()

    this.applicableFreePrintCheck()
    this.applicableCouponCheck()
  },
  methods: {
    ...mapActions(["getPromotionPrice", "tossInit", "reqPicturePrint", "cancelPayStatusUpdate"]),

    showCouponSelectDialog(showNoCoupon = true) {
      this.showNoCoupon = showNoCoupon
      this.show_coupon_select_componentKey++
      this.show_coupon_select = true
    },

    async getQrocdeInfo() {
      //this.pic.qrcode 정보를 통해서 qrcode_list  qrcode 정보를 가져온다.
      const qrcode = this.pic.qrcode
      return this.$http.get("/api/qrcode/select/freePrinted/byQrCode", { params: { qrcode } })
    },

    applicableFreePrintCheck() {
      // 무료 인쇄 가능 여부 체크
      // this.pic.qrcode 정보를 통해서 qrcode_list 에서 printed 정보 여부로 판단한다.      
      this.getQrocdeInfo().then((res) => {
        console.log(res)
        if (res.data.length > 0) {
          this.canFreePrint = res.data[0].free_printed === null
        }
      })
    },

    useFreePrint() {
      if (this.canFreePrint) {
        this.$http
          .post("/api/qrcode/update/freePrintedStatus/byQrCode", { params: { qrcode: this.pic.qrcode } })
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },

    applicableCouponCheck() {
      this.$http
        .get("/api/coupon/getUserCoupons", {
          params: {
            user_id: this.lafia_user.user_id,
            type: "인쇄",
          },
        })
        .then((res) => {
          let coupons = res.data
          this.available_coupon_cnt = coupons.length
        })
        .catch((err) => {
          this.$toast.error(this.$t("toast.error-get-user-coupons"), {
            position: "top",
          })
        })
    },

    applyCoupon(coupon) {
      console.log("applyCoupon", coupon)
      this.selected_coupon = null

      if (!coupon) {
        return
      }
      const { discount_KRW, startAt, expireAt, type, isExpired } = coupon
      if (isExpired) {
        return
      } else if (!this.dayjs().isBetween(this.dayjs(startAt), this.dayjs(expireAt), "day", "[]")) {
        return
      } else if (type !== "인쇄권할인") {
        return
      }
      this.selected_coupon = coupon
    },
    onSubmitWithApplicableCouponCheck: _.debounce(async function () {
      if (this.computed_total_price > 0 && this.available_coupon_cnt > 0 && !this.selected_coupon) {
        this.showTwoButtonDialog("dialog.confirm_applicable_coupons")
      } else {
        this.onSubmit()
      }
    }, 500),

    onSubmit: _.debounce(async function () {
      if (!this.lafia_user.is_logined) {
        let loginPath = "/auth/login"
        this.$router.push(loginPath)
        return
      }

      try {

        const qrcodeInfo = await this.getQrocdeInfo()
        if (qrcodeInfo.length > 0) {
          const isCanFreePrint = qrcodeInfo[0].free_printed === null
          if (isCanFreePrint !== this.canFreePrint) {
            this.canFreePrint = isCanFreePrint
            alert(this.$t("alert.error_free_print_status_changed"))
            return
          }
        }

        // 결제 진행중
        this.pay_loading = true

        let picInfoParams = {
          user_id: this.lafia_user.user_id,
          fileName: this.pic.filename,
          updated: this.pic.updated,
          conceptName: this.pic.title,
          count: this.printCount,
          kiosk: this.pic.kiosk,
          printUrl: this.pic.printUrl,
        }

        let orderId = Math.floor(Math.random() * 1000000000)

        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: this.lafia_user.user_id,
          orderDetailList: [
            {
              productName: "온라인인쇄",
              orderNum: this.orderCount,
              price: this.default_package_price.printPrice || 3900,
              totalPrice: this.computed_total_price_original,
              finalPrice: this.computed_total_price,
              couponId: this.selected_coupon && this.selected_coupon.issuedId,
            },
          ],
          memo: JSON.stringify(picInfoParams),
          type: "온라인인쇄",
        })

        if (this.computed_total_price > 0) {
          // 모든 작업이 완료된 후 토스 결제
          if (process.env.VUE_APP_TOSS_TEST_PAYMENT === "true" && window.confirm("테스트 결제 하기")) {
            this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=test&amount=" + this.computed_total_price)
          } else {
            let orderName = "루시드 카드 인쇄 " + this.printCount + "회 " + "(" + this.pic.title + ")"
            if (this.isSupportAbroadPay && !this.isKRW) {
              const { online_url, mobile_url } = await payletterService.requestInternationalPay({
                storeorderno: orderId.toString(),
                amount: this.computed_total_price,
                currency: "KRW",
                payinfo: orderName,
                payeremail: this.lafia_user.email,
                payerid: this.lafia_user.user_id,
              })

              var popOption = "width=430, height=555, resizable=no, scrollbars=no, status=no;"
              var popGlOption = "width=800, height=600, resizable=no, scrollbars=no, status=no;"
              if (this.$vuetify.breakpoint.mobile) {
                this.payletterWindow = window.open(mobile_url, "", popOption)
              } else {
                this.payletterWindow = window.open(online_url, "", popGlOption)
              }

              if (!this.payletterWindow || this.payletterWindow.closed || typeof this.payletterWindow.closed == "undefined") {
                alert(this.$t("alert.error_popup_blocked"))
                return
              }

              if (this.payletterWindow) {
                this.payletterWindowEventListener = async (event) => {
                  if (event.data.message === "pay-completed") {
                    const payloadData = event.data.data
                    this.payletterEventUnregister()
                    const { retCode, orderNo, status } = payloadData
                    if (retCode == "0") {
                      this.useFreePrint()
                      // 성공
                      this.$router.push("/payletter/success?orderId=" + orderId + "&amount=" + this.computed_total_price)
                    } else {
                      // 실패
                      this.$router.push("/fail?orderId=" + orderId + "&message=" + status)
                    }
                  }
                }
                window.addEventListener("message", this.payletterWindowEventListener)
              }
            } else {
              var tossPayments = TossPayments(process.env.VUE_APP_TOSS_PAYMENT_CLIENT_KEY)
              tossPayments
                .requestPayment("카드", {
                  amount: this.computed_total_price,
                  orderId: orderId,
                  orderName,
                  customerName: this.lafia_user.name,
                  customerEmail: this.lafia_user.email,
                  successUrl: window.location.origin + "/toss/success",
                  failUrl: window.location.origin + "/fail",
                  useEscrow: false,
                })
                .then((res) => {
                  this.useFreePrint()
                })
                .catch((error) => {
                  console.error("토스페이먼츠 결제", error)
                  this.cancelPayStatusUpdate({ order_id: orderRes.data.id, reason: "사용자취소" })
                })
            }
          }
        } else {
          const result = await this.$http.post("/api/order/update/status", {
            params: {
              id: orderRes.data.id,
              status: "결제완료",
            },
          })
          // 사진 인화 요청 api 호출
          if (result.data.affectedRows) {
            picInfoParams = {
              ...picInfoParams,
              imageUrl: this.pic.printUrl || this.$imageUrl(`${kStorageUrl}/complete/${picInfoParams.fileName}`, this.pic.updated),
              orderId: orderId.toString(),
            }
            const res = await this.reqPicturePrint(picInfoParams)
            this.useFreePrint()
          } else {
            alert(this.$t("alert.error_failed_print_request"))
          }
        }        

      } catch (e) {
        console.log(e)
      } finally {
        this.pay_loading = false
      }
    }, 500),

    showTwoButtonDialog(message) {
      this.twobutton_dialog_messsage = message
      this.show_twobutton_dialog = true
      this.show_twobutton_dialog_componentKey++
    },

    closeTwoButtonDialog() {
      this.show_twobutton_dialog = false
      this.twobutton_dialog_messsage = ""
      this.show_twobutton_dialog_componentKey++
    },

    positiveDialogEvent() {
      this.closeTwoButtonDialog()
      this.onSubmit()
    },

    negativeDialogEvent() {
      this.closeTwoButtonDialog()
      this.showCouponSelectDialog(false)
    },

    twoButtonDialogInputEvent(e) {
      if (!e) {
        this.closeTwoButtonDialog()
      }
    },

    payletterEventUnregister() {
      console.log("payletterEventUnregister")
      if (this.payletterWindowEventListener) {
        window.removeEventListener("message", this.payletterWindowEventListener)
      }
      this.payletterWindowEventListener = null
      this.payletterWindow = null
    },


    getResourceKey(key) {
      if (this.isFaceberry) {
        return key + '_faceberry'
      }
      return key;
    },
  },

  beforeDestroy() {
    this.payletterEventUnregister()
  },

}
</script>
<style scoped>
::v-deep v-select .v-menu__content {
  z-index: 12 !important;
}
</style>
