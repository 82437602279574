import { render, staticRenderFns } from "./MyFaceberryDetail.vue?vue&type=template&id=739a4fae&scoped=true"
import script from "./MyFaceberryDetail.vue?vue&type=script&lang=js"
export * from "./MyFaceberryDetail.vue?vue&type=script&lang=js"
import style0 from "./MyFaceberryDetail.vue?vue&type=style&index=0&id=739a4fae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739a4fae",
  null
  
)

export default component.exports