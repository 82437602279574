class FaceMeshEditor {
  constructor(faceMesh, cbUpdate) {
    this.faceMesh = faceMesh
    this.cbUpdate = cbUpdate

    /** 가우시안 범위 제어 */
    this.sigma = 25

    /** 밝기 제어 */
    this._brightness = 0

    this._leftEyeValue = 0
    this._rightEyeValue = 0

    this._leftCheekValue = 0
    this._rightCheekValue = 0

    this._leftNostrilValue = 0
    this._rightNostrilValue = 0

    this._leftCheekValue = 0
    this._rightCheekValue = 0

    this._faceWidthValue = 0

    this._midFaceValue = 0

    this._flip = false

    this.lazyUpdate = false
    this.workTimer = null
  }

  get leftEyeValue() {
    return this._leftEyeValue
  }

  set leftEyeValue(value) {
    this._leftEyeValue = value
    this._applyAllWarps()
  }

  get rightEyeValue() {
    return this._rightEyeValue
  }

  set rightEyeValue(value) {
    this._rightEyeValue = value
    this._applyAllWarps()
  }

  get leftCheekValue() {
    return this._leftCheekValue
  }

  set leftCheekValue(value) {
    this._leftCheekValue = value
    this._applyAllWarps()
  }

  get rightCheekValue() {
    return this._rightCheekValue
  }

  set rightCheekValue(value) {
    this._rightCheekValue = value
    this._applyAllWarps()
  }

  get leftNostrilValue() {
    return this._leftNostrilValue
  }

  set leftNostrilValue(value) {
    this._leftNostrilValue = value
    this._applyAllWarps()
  }

  get rightNostrilValue() {
    return this._rightNostrilValue
  }

  set rightNostrilValue(value) {
    this._rightNostrilValue = value
    this._applyAllWarps()
  }

  get faceWidthValue() {
    return this._faceWidthValue
  }

  set faceWidthValue(value) {
    this._faceWidthValue = value
    this._applyAllWarps()
  }

  get flip() {
    return this._flip
  }

  set flip(value) {
    this._flip = value
    this._applyAllWarps()
  }

  get brightness() {
    return this._brightness
  }

  set brightness(value) {
    this._brightness = value
    this._applyAllWarps()
  }

  get midFaceValue() {
    return this._midFaceValue
  }

  set midFaceValue(value) {
    this._midFaceValue = value
    this._applyAllWarps()
  }

  applyEditValues(editValues) {
    this.lazyUpdate = true

    this.brightness = editValues.brightness || 0

    this.flip = editValues.flip

    this.leftEyeValue = editValues.leftEye
    this.rightEyeValue = editValues.rightEye

    this.leftNostrilValue = editValues.leftNostril
    this.rightNostrilValue = editValues.rightNostril

    this.leftCheekValue = editValues.leftCheek
    this.rightCheekValue = editValues.rightCheek

    this.faceWidthValue = editValues.faceWidth

    this.midFaceValue = editValues.midFace

    this.lazyUpdate = false
    this._applyAllWarps()
  }

  _applyAllWarps() {
    if (this.lazyUpdate) {
      return
    }

    this.faceMesh.setBrightness(this._brightness * 0.01)
    this.faceMesh.planeMesh.scale.set(this._flip ? -1 : 1, 1, 1)
    this.cbUpdate()

    // Create worker if not exists
    if (!this.worker) {
      const Worker = require("worker-loader!./face-mesh-edit-worker.js").default
      this.worker = new Worker()

      this.worker.onmessage = (e) => {
        const { positions } = e.data

        // Update geometry with new positions
        const posAttr = this.faceMesh.planeGeometry.attributes.position
        posAttr.array.set(positions)
        posAttr.needsUpdate = true

        this.cbUpdate()
      }
    }

    // Only send message if worker exists
    if (this.worker) {
      // Prepare data for worker
      const data = {
        landmarks: this.faceMesh.landmarks,
        originalPositions: this.faceMesh.originalPositions,

        leftEyeCenter: this.faceMesh.leftEyeCenter,
        rightEyeCenter: this.faceMesh.rightEyeCenter,

        imageWidth: this.imageWidth,
        imageHeight: this.imageHeight,

        sigma: this.sigma,

        leftEyeValue: this.leftEyeValue,
        rightEyeValue: this.rightEyeValue,

        leftNostrilValue: this.leftNostrilValue,
        rightNostrilValue: this.rightNostrilValue,

        leftCheekValue: this.leftCheekValue,
        rightCheekValue: this.rightCheekValue,

        faceWidthValue: this.faceWidthValue,

        midFaceValue: this.midFaceValue,

        geometry: {
          parameters: this.faceMesh.planeGeometry.parameters,
          positions: Array.from(this.faceMesh.planeGeometry.attributes.position.array),
        },
      }

      if (this.workTimer) {
        clearTimeout(this.workTimer)
      }

      this.workTimer = setTimeout(() => {
        // Send data to worker
        this.worker.postMessage(data)
        this.workTimer = null
      }, 0)
    }
  }

  get planeMesh() {
    return this.faceMesh.planeMesh
  }

  get imageWidth() {
    return this.faceMesh.imageWidth
  }

  get imageHeight() {
    return this.faceMesh.imageHeight
  }
}

export default FaceMeshEditor
